import { graphql } from "generated";
import { constructLoginRedirectPath, urqlClient } from "lib/provideUrql";
import { LoaderFunction, redirect } from "react-router-dom";

const RDContractHandler_GetMe = graphql(`
    query RDContractHandler_GetMe {
        me {
            id
        }
    }
`)
export const RDContractHandler: LoaderFunction = async () => {
    const r = await urqlClient.query(RDContractHandler_GetMe, {})

    if (r.data?.me?.__typename != null) {
        window.location.assign(`https://ucf.qualtrics.com/jfe/form/SV_9zrG239Tq2FUy5o?UserId=${r.data?.me.id}`)
        return null
    }

    return redirect(constructLoginRedirectPath("/forms/rd-contract-fall2024"))
}